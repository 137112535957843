<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify"></Notify>
      <v-row>
        <v-col cols="12">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{ $t("news.News") }}</v-toolbar-title>
                <button
                  v-if="
                    $store.getters['auth/str_per'].indexOf('cms-news-create') >
                    -1
                  "
                  class="py-5 mb-0 ms-auto"
                  @click="add_news()"
                >
                  <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
                </button>
              </v-app-bar>
              <FormFilter
                :filters="filters"
                @get_data="get_news"
                @reset="resetFilters"
              ></FormFilter>
              <Table
                ref="table"
                @set_option="set_option"
                @change_pre_page="ChangePrePage"
                :loading="loading"
                :items="news"
                :headers="header"
                :btns="btns"
                @action="action"
                :paginate="paginate"
                :btn_table="btn_table"
                @event_btn="event_btn"
                :btn_loading="btn_loading"
                @get_data_paginate="get_data_paginate"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <Modal :data="modal_data"></Modal>
    </v-container>
  </div>
</template>
<script>
import Table from "../Components/PaginationTable.vue";
import Notify from "../Components/New/Notify.vue";
import Modal from "../Components/New/Modal.vue";
import FormFilter from "../Components/Filter.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("cms");
export default {
  name: "News-Page",
  components: {
    Table,
    Notify,
    Modal,
    FormFilter,
  },
  data() {
    return {
      options: {},
      filters: [],
      btn_table: {
        excel: true,
        print: false,
      },
      btn_loading: {
        excel: false,
        print: false,
      },
      modal_data: {
        size: "800px",
        title: "",
      },
      paginate: {
        page: 1,
        itemsPerPage: 0,
        total: 0,
      },
      loading: true,
      image: null,
      message: {
        msg: null,
        type: null,
      },
      btns: [
        {
          type: "icon",
          text: "comments",
          color: "bg-gradient-danger",
          icon: "mdi-eye",
          permission: "cms-news-comments",
        },
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          permission: "cms-news-update",
        },
        {
          type: "icon",
          text: "destroy",
          color: "bg-gradient-danger",
          icon: "mdi-delete",
          permission: "cms-news-delete",
        },
      ],
      header: [
        { text: this.$i18n.t("image"), value: "image", align: "center" },
        { text: this.$i18n.t("cycle.title"), value: "title", align: "center" },
        {
          text: this.$i18n.t("news.subject"),
          value: "subject",
          align: "center",
        },
        { text: this.$i18n.t("news.body"), value: "body", align: "center" },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      form_data: {
        title: "",
        image: null,
      },
      form_style: [
        {
          col: "6",
          type: "text",
          label: this.$i18n.t("cycle.title"),
          error: null,
          value_text: "title",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "6",
          type: "text",
          label: this.$i18n.t("news.subject"),
          error: null,
          value_text: "subject",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          type: "textarea",
          rows: 6,
          label: this.$i18n.t("news.body"),
          error: null,
          value_text: "body",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          action: true,
          type: "file",
          image: true,
          accept: "image/*",
          label: this.$i18n.t("image"),
          error: null,
          value_text: "image",
          type_file: "single",
          rules: [
            (value) =>
              !value ||
              value.size < 2000000 ||
              this.$i18n.t("form.file size should be less than 2 MB"),
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState(["news"]),
  },
  methods: {
    ...mapActions(["getNews", "destroyNews"]),
    get_news(reset = false, type = null) {
      this.loading = true;
      const formData = new FormData();
      if (!reset) {
        this.filters.filter(function (filter) {
          formData.append(filter.name, filter.value);
        });
      }
      if (type == "pre_page") {
        formData.append("paginate", this.paginate.itemsPerPage);
      }
      this.getNews({ page: this.paginate.page, data: formData }).then(
        (response) => {
          this.loading = false;
          this.paginate.total = response.data.news.total;
          this.paginate.itemsPerPage = parseInt(response.data.news.per_page);
          if (reset) {
            this.filters = response.data.filters;
            this.options = {};
            this.$refs.table.resetOption();
          }
        },
        (error) => {
          console.log(error);
          this.loading = false;
          if (error.response.status != 401) {
            this.$store.dispatch("form/setNotify", {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            });
          }
        }
      );
    },
    action(id, name, item) {
      if (name == "edit") {
        this.edit_news(item);
      } else if (name == "destroy") {
        this.destroy_news(id);
      } else if (name == "comments") {
        this.get_comments(id);
      }
    },
    get_comments(id) {
      this.$router.push("/news/comments/" + id.toString());
    },
    add_news() {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title = this.$i18n.t("news.New News");
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.dispatch("form/setData", this.form_style);
      this.$store.commit("form/INFO_CALL", {
        name: "cms/addNews",
      });
    },
    edit_news(item) {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title =
        this.$i18n.t("news.Edit News") + " # " + item.title;
      this.$store.dispatch("form/setData", this.form_style);
      this.form_data.title = item.title;
      this.form_data.subject = item.subject;
      this.form_data.body = item.body;
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.commit("form/INFO_CALL", {
        name: "cms/editNews",
        id: item.id,
      });
    },
    destroy_news(id) {
      this.$swal({
        title: this.$i18n.t("general.Are you sure?!"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("general.Yes, delete it!"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.destroyNews(id).then(
            (response) => {
              // console.log(response)
              this.$swal.fire(
                this.$i18n.t("general.Deleted!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              console.log(error);
              this.$swal.fire(
                this.$i18n.t("general.Error"),
                this.$i18n.t("general.There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("general.Cancelled Delete"),
            "error"
          );
        }
      });
    },
    get_data_paginate(page) {
      this.paginate.page = page;
      this.get_news();
    },
    set_option(val) {
      this.options = val;
    },
    ChangePrePage(page) {
      this.paginate.itemsPerPage = parseInt(page);
      this.paginate.page = 1;
      this.get_news(false, "pre_page");
    },
    resetFilters() {
      this.paginate.page = 1;
      this.get_news(true);
    },
    event_btn(name) {
      if (name == "excel") {
        this.export_excel();
      } else if (name == "print") {
        this.print_table();
      }
    },
    export_excel() {
      this.btn_loading.excel = true;
      const formData = new FormData();
      this.filters.filter(function (filter) {
        formData.append(filter.name, filter.value);
      });
      this.$http({
        url: this.$baseURL + "api/cms/news/export",
        responseType: "blob",
        method: "post",
        data: formData,
      }).then(
        (response) => {
          this.btn_loading.excel = false;
          // console.log(response);
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "News_" + new Date().toLocaleString() + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          this.btn_loading.excel = false;
          if (error.response.status != 401) {
            this.$store.dispatch("form/setNotify", {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            });
          }
        }
      );
    },
  },
  mounted() {
    this.get_news(true);
    document.title = this.$i18n.t("news.News");
  },
};
</script>
